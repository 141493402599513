import { useEffect, useState } from 'react'
import { BACKEND_URL } from '../constants'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from '@reach/router'
import Post from './Post'

const Posts = () => {
  const [posts, setPosts] = useState([])

  useEffect(() => {
    const getPosts = async () => {
      const response = await fetch(`${BACKEND_URL}/posts`)
      setPosts(await response.json())
    }

    getPosts()
  }, [])

  return (
    <Container>
      <h1 className="my-4 text-center">Feed</h1>
      <Row>
        {posts.map((post, i) => (
          <Col lg={6} key={`post-${i}`}>
            <Post post={post}></Post>
          </Col>
        ))}
      </Row>
      <Link to="/create-post">Create Post</Link>
    </Container>
  )
}

export default Posts
