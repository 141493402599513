import { Card } from 'react-bootstrap'

const Post = (props) => {
  return (
    <Card className="mb-4">
      <Card.Header>
        <div className="d-flex">
          <span> {props.post.title}</span>
          <span className="ms-auto">@{props.post.username}</span>
        </div>
      </Card.Header>
      <Card.Body>{props.post.content}</Card.Body>
    </Card>
  )
}

export default Post
