import { Router } from '@reach/router'
import Posts from './components/Posts'
import CreatePost from './components/CreatePost'
import 'bootstrap/dist/css/bootstrap.min.css'

function App() {
  return (
    <Router>
      <Posts path="/" />
      <CreatePost path="/create-post" />
    </Router>
  )
}

export default App
