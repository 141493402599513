import { Button, Container, Form } from 'react-bootstrap'
import { BACKEND_URL } from '../constants'
import { navigate } from '@reach/router'

const CreatePost = () => {
  const onSubmit = (event) => {
    event.preventDefault()

    const form = event.currentTarget
    const elements = form.elements
    const newPost = {
      username: elements.username.value,
      title: elements.title.value,
      content: elements.content.value,
    }

    const doMakePost = async () => {
      await fetch(`${BACKEND_URL}/posts`, {
        method: 'POST',
        body: JSON.stringify(newPost),
      })
      navigate('/')
    }
    doMakePost()
  }

  return (
    <Container>
      <Form className="mt-3" onSubmit={onSubmit}>
        <Form.Group className="mb-3">
          <Form.Label>Title</Form.Label>
          <Form.Control type="text" name="title" required></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Content</Form.Label>
          <Form.Control
            as="textarea"
            style={{ height: '100px' }}
            name="content"
            required
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3">
          <Form.Label>Username</Form.Label>
          <Form.Control
            type="text"
            placeholder="user123"
            name="username"
            required
          ></Form.Control>
        </Form.Group>{' '}
        <Button type="submit">Create</Button>
      </Form>
    </Container>
  )
}

export default CreatePost
